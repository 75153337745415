@use './../bootstrap/variables' as *;

.main-header {
    background-color: transparent;
    border-bottom: 1px solid #f9fafb00;
    font-family: 'Inter-Bold', sans-serif;
    position: fixed;
    top: 0;
    transform: translateY(0);
    transition: all 0.2s ease;
    width: 100%;
    z-index: 103;

    @media (max-width: 991px) {
        max-height: 80px;
    }

    @media (min-width: 992px) {
        padding: 18.5px 0;

    }

    &.scroll-up,
    &.scroll-down {
        background-color: $white;
        border-bottom: 1px solid rgba(29, 44, 66, 0.2);
        transition: all 0.2s ease;
        transition-delay: 0.05s;
    }

    &.scroll-down {
        transform: translateY(-150px);
        transition: all 0.6s ease;
        transition-delay: 0.3s;
    }
}

.nav-control {
    display: contents;

    @media (min-width: 992px) {
        display: flex;
    }
}
