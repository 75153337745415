@use './../bootstrap/variables' as *;

.book-detail {
    margin-bottom: 50px;
    margin-top: 30px;

    @media (min-width: 992px) {
        margin-bottom: 63px;
        margin-top: 80px;
    }

    .row {
        &:first-of-type {
            margin-bottom: 50px;

            @media (min-width: 992px) {
                margin-bottom: 60px;
            }
        }

        &:not(:first-of-type) {
            border-top: 1px solid #ecf2f9;
        }
    }

    .side-one {
        margin-top: 2px;
        position: relative;

        .img-box {
            display: flex;
            justify-content: center;
            margin: auto auto 36px;
            max-width: 240px;
            position: relative;

            @media (min-width: 992px) {
                display: block;
                margin: 0 0 36px;
                max-width: 320px;
            }

            .preview {
                box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.6);
                height: 100%;
                object-fit: contain;
                width: 100%;
            }
        }
    }

    .side-two {
        h2 {
            font-size: 30px;
            line-height: 1;
            margin-bottom: 1rem;

            @media (min-width: 992px) {
                font-size: 36px;
            }
        }

        .authors {
            color: $title;
            margin-bottom: 30px;

            @media (min-width: 992px) {
                margin-bottom: 37px;
            }

            span {
                font-size: 18px;
                line-height: 150%;

                @media (min-width: 992px) {
                    font-size: 24px;
                }
            }

            .author {
                display: inline-block;
                font-size: 20px;

                @media (min-width: 992px) {
                    font-size: 30px;
                }
            }

            .issue-year {
                display: block;
            }
        }

        .description {
            font-size: 16px;
            padding-bottom: 0.33rem;

            @media (min-width: 992px) {
                font-size: 18px;
            }
        }

        .bookstore.dropdown {
            margin-bottom: 50px;
            text-transform: uppercase;

            .dropdown-menu,
            .dropdown-toggle {
                --bs-btn-padding-x: 1px;
                width: 100%;

                @media (min-width: 678px) {
                    width: 180px;
                }
            }

            @media (min-width: 992px) {
                margin-bottom: 67px;
            }

            .dropdown-toggle {
                display: flex;
                justify-content: space-between;
                padding-left: 20px;
                padding-right: 20px;

                &::after {
                    border: 0;
                    content: url('./../img/button-arrow.svg');
                }
            }
        }
    }
}

.author-next-books,
.license,
.bibliographic-data,
.for-download {
    margin-bottom: 50px;
    padding-top: 30px;

    .side-one {
        h3 {
            font-size: 20px;
            margin-bottom: 20px;

            @media (min-width: 992px) {
                font-size: 24px;
            }
        }
    }

    .description {
        font-size: 16px;

        @media (min-width: 992px) {
            font-size: 18px;
        }

        > div {
            display: flex;
            font-size: 16px;

            @media (min-width: 992px) {
                font-size: 18px;
            }

            p:first-of-type {
                min-width: 123px;

                @media (min-width: 992px) {
                    min-width: 147px;
                }
            }

            p {
                margin-bottom: 0;
            }
        }
    }
}

.author-next-books {
    > div:nth-of-type(2) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-left: -2%;
        margin-right: -2%;

        @media (min-width: 992px) {
            margin-left: -0.967%;
            margin-right: -0.967%;
        }

        .authors-more-books {
            border: 1px solid $border;
            border-radius: 8px 8px 0 0;
            display: flex;
            flex-direction: column;
            margin: 0 2% 30px;
            margin-bottom: 20px;
            position: relative;
            width: 46%;

            @media (min-width: 992px) {
                margin: 0 1.667% 30px;
                width: 176px;
            }

            .img-box {
                background-color: $light;
                border-radius: 8px 8px 0 0;
                display: block;
                padding: 16px;
                max-width: 100%;
                width: 100%;

                img {
                    display: block;
                    margin: auto;
                    max-width: 206px;
                }

                @media (min-width: 992px) {
                    padding: 26px;
                }
            }
        }

        img {
            box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.6);
            height: 100%;
            object-fit: contain;
            width: 100%;
        }

        .book-content {
            color: $title;
            font-size: 14px;
            line-height: 130%;
            padding: 14px 16px;

            @media (min-width: 992px) {
                padding: 14px 26px;
            }

            h4 {
                font-size: 14px;
            }
        }
    }
}

.book-detail,
.for-download {
    .download,
    .download-sample {
        margin: 0;
        padding: 0;

        li {
            list-style-type: none;
        }

        a {
            font-size: 16px;
            line-height: 41px;
            text-decoration: underline;

            @media (min-width: 992px) {
                font-size: 18px;
            }
        }

        span.file-ext {
            text-transform: uppercase;
        }
    }

    .download-sample {
        margin: auto;
        max-width: 240px;

        @media (min-width: 992px) {
            display: block;
            margin: 0;
            max-width: 320px;
        }

        li {
            text-align: center;

            a::before {
                content: url('./../img/eye.svg');
                display: inline-block;
                transform: translate(-12px, 0);
            }
        }
    }
}
