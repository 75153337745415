
.btn-primary {
    @include button-variant( // bg
        $primary,
        // border-color
        $primary,
        // color
        $white,
        // hover:bg
        #667995,
        // hover:border-color
        #667995,
        // hover:color
        $white,
        // active:bg
        $dark,
        // active:border-color
        $dark,
        // active:color
        $white );
}

.btn-secondary {
    @include button-variant( // bg
        $white,
        // border-color
        $white,
        // color
        $primary,
        // hover:bg
        $border,
        // hover:border-color
        $border,
        // hover:color
        $primary,
        // active:bg
        #667995,
        // active:border-color
        #667995,
        // active:color
        $white);
}

.pagination li span,
.pagination li a,
.btn-light,
.btn-info {
    @include button-variant( // bg
        $white,
        // border-color
        $primary,
        // color
        $primary,
        // hover:bg
        $border,
        // hover:border-color
        $primary,
        // hover:color
        $primary,
        // active:bg
        #667995,
        // active:border-color
        $primary,
        // active:color
        $white);
}

.btn-info {
    border-width: 2px;
}
