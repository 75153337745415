@use './../bootstrap/variables' as *;

.pagination {
    align-items: center;
    display: flex;
    justify-content: center;

    li {
        list-style-type: none;
        margin: 10px;

        span,
        a {
            border: 2px solid $primary;
            border-radius: 0.5rem;
            color: $primary;
            font-family: Inter-Bold, sans-serif;
            font-size: 1rem;
            padding: 1rem;
        }

        > a {
            &:hover,
            &:focus {
                background-color: $info;
                text-decoration: none;
            }
        }

        &.active {
            span {
                background-color: $info;
                text-decoration: none;
            }
        }
    }

    .badge {
        display: none;
    }
}

.djangocms-newsblog-article-detail {
    .pagination {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 0;
        padding-top: 50px;

        li {
            list-style-type: none;
            padding: 1rem;
        }

        li.previous-page a::before {
            content: "←";
            left: -0.2em;
            position: relative;
        }

        li.next-page a::after {
            content: "→";
            position: relative;
            right: -0.2em;
        }

        li.back-to-overview {
            &::before {
                content: "–";
                left: -0.2em;
                position: relative;
            }

            &::after {
                content: "–";
                position: relative;
                right: -0.2em;
            }
        }
    }
}
