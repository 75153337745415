@use './../bootstrap/variables' as *;

.book-detail {
    .nav.nav-tabs {
        border: 0;
        margin-bottom: 0;
        padding: 0;

        @media (min-width: 845px) {
            display: block;
            padding: 0;
        }

        .nav-link {
            background-color: unset;
            border: 0;
            border-right: 0;
            color: $dark;
            cursor: pointer;
            display: inline-block;
            font-family: Inter-Regular, sans-serif;
            font-size: 16px;
            padding: 0;
            position: relative;
            text-decoration: underline;

            @media (min-width: 845px) {
                font-size: 18px;
            }

            &::after {
                background: url('../img/collapse_arrow.svg') center center no-repeat;
                content: '';
                cursor: pointer;
                display: block;
                height: 30px;
                position: absolute;
                right: -35px;
                top: 0;
                transform: rotate(180deg);
                transition: all 0.2s ease-in-out;
                width: 30px;

            }

            &.arrow-close {
                &::after {
                    transform: rotate(0deg);
                }
            }
        }

        li {
            &:first-of-type > * {
                border-radius: 0;
            }

            &:last-of-type > * {
                border-radius: 0;
                border-right: 0;
            }
        }

        .nav-item.active {
            .nav-link {
                background-color: unset !important;
            }
        }
    }

    .tab-content {
        font-size: 16px;

        @media (min-width: 845px) {
            font-size: 18px;
        }

        article {
            border-bottom: 1px solid $info;
            padding: 18px 0;

            aside > *:last-of-type {
                padding-bottom: 0;
            }

            > aside:first-of-type > p,
            aside aside:last-of-type > p {
                margin-bottom: 0;
            }

            &:last-of-type {
                border-bottom: 0;
            }
        }
    }

    @media (max-width: 1199px) {
        .nav.nav-tabs {
            display: block;
            overflow-x: unset;
        }

        .nav-item {
            flex-shrink: 0;
        }
    }
}

.tab-content {
    overflow: hidden;
    position: relative;
    transition: all 0.25s ease-in-out;

    > * {
        position: absolute;
    }

    > .tab-pane {
        display: block;
        z-index: 1;
    }

    > .active {
        background-color: $white !important;
        display: block;
        z-index: 2;
    }
}
