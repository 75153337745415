@use './../bootstrap/variables' as *;

.catalog-books-sort-by,
.books-list-side {
    ul {
        padding-left: 1.8rem;
    }

    li {
        list-style: none;
        margin: 8px auto;
    }

    input {
        position: absolute;
    }

    span {
        font-size: 1.1rem;
    }
}

.search-group {
    input {
        border-right: 0;
        color: $dark;
        font-family: 'Inter-Regular', sans-serif;
        font-size: 18px;

        &::placeholder {
            font-family: 'Inter-Regular', sans-serif;
        }
    }

    .btn-search {
        background-color: unset;
        border: 1px solid $border;
        border-left: 0;
        border-radius: 0 8px 8px 0;
    }
}
