@use './../bootstrap/variables' as *;

.catalog-books-sort-by,
.books-list-filter-mobile,
.catalog-books-filter {
    border-bottom: 1px solid #ecf2f9;
    margin-bottom: 20px;
    overflow: hidden;
    padding-bottom: 20px;

    > h4 {
        cursor: pointer;
        margin-bottom: 0;
        position: relative;

        &::after {
            background: url('../img/collapse_arrow.svg') center center no-repeat;
            content: '';
            display: block;
            height: 30px;
            position: absolute;
            right: 0;
            top: 0;
            transform: rotate(180deg);
            transition: all 0.2s ease-in-out;
            width: 30px;
        }

        &[aria-expanded='true']::after {
            transform: rotate(0deg);
            transition: all 0.2s ease-in-out;
        }
    }
}

.books-list-filter-mobile {
    border-bottom: 0;

    > h4 {
        border-bottom: 1px solid;
        margin-bottom: 30px;
        padding-bottom: 20px;

        &::after {
            background: url('../img/collapse_arrow.svg') center center no-repeat;
            transform: rotate(180deg);
        }

        &[aria-expanded='true']::after {
            background: url('../img/collapse_x.svg') center center no-repeat;
        }
    }

    .catalog-books-filter {
        padding-bottom: 20px;

    }
}
