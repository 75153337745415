@use './../bootstrap/variables' as *;

.nav.nav-tabs {
    border: 0;
    margin-bottom: 25px;
    padding: 50px 0 25px;

    @media (min-width: 845px) {
        align-items: center;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        margin-bottom: 35px;
        padding: 40px 0 35px;
    }

    .nav-link {
        border: 1px solid $primary;
        border-right: 0;
        font-family: Inter-Bold, sans-serif;
    }

    li {
        &:first-of-type > * {
            border-radius: 8px 0 0 8px;
        }

        &:last-of-type > * {
            border-radius: 0 8px 8px 0;
            border-right: 1px solid;
        }
    }

    .nav-item.active {
        .nav-link {
            background-color: $info;
        }
    }
}

.tab-content {
    article {
        border-bottom: 1px solid $info;
        padding: 18px 0;

        aside > *:last-of-type {
            padding-bottom: 0;
        }

        > aside:first-of-type > p,
        aside aside:last-of-type > p {
            margin-bottom: 0;
        }

        &:last-of-type {
            border-bottom: 0;
        }
    }

    .about-cards.active {
        align-items: stretch;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        > * {
            margin-bottom: 20px;
            width: 100%;

            @media (min-width: 992px) {
                padding: 0 1rem;
                width: 33%;
            }
        }
    }

    .bg-body {
        background-color: inherit !important;
    }
}

@media (max-width: 1199px) {
    .nav.nav-tabs {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
    }

    .nav-item {
        flex-shrink: 0;
    }
}
