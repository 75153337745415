@use './../bootstrap/variables' as *;

.row.books-list {
    margin-top: 40px;

    @media (min-width: 992px) {
        margin-top: 80px;
    }
}

.books-list-navigation {
    background-color: $light;
    border-radius: 6px;
    display: none;
    justify-content: space-between;
    padding: 6px 10px;

    @media (min-width: 992px) {
        display: flex;
        margin-bottom: 30px;
    }

    > span:first-of-type {
        display: none;

        @media (min-width: 992px) {
            display: block;
        }
    }

    [title*="seznam"] {
        margin-left: 13px;
    }

    [title*="dlaždice"] {
        cursor: pointer;
    }

    .dropdown-toggle {
        font-family: 'Inter-Bold';
        font-size: 18px;
        line-height: 150%;
    }
}

.navigation-sort label {
    font-family: 'Inter-Bold';
    font-size: 18px;
    margin-right: 0.66rem;
}

.books-tiles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -2%;
    margin-right: -2%;

    @media (min-width: 992px) {
        margin-left: -1.25%;
        margin-right: -1.25%;
    }

    .book-item {
        border: 1px solid $border;
        border-radius: 8px 8px 0 0;
        display: flex;
        flex-direction: column;
        margin: 0 2% 30px;
        margin-bottom: 20px;
        position: relative;
        width: 46%;

        @media (min-width: 992px) {
            margin: 0 1.25% 30px;
            width: 22.5%;
        }

        .img-box {
            background-color: $light;
            border-radius: 8px 8px 0 0;
            display: block;
            margin-bottom: 13px;
            padding: 16px 16px 22px;

            @media (min-width: 992px) {
                padding: 44px 40px;
            }
        }

        img {
            box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.6);
            height: 100%;
            object-fit: contain;
            width: 100%;
        }

        .book-title {
            font-family: 'Inter-Bold';
            font-size: 18px;
            margin-bottom: 7px;
        }

        .book-content {
            margin: 0 20px 12px;
        }

        .authors {
            font-size: 18px;
        }

        .book-description,
        .book-detail-link {
            display: none;
        }

        &.hidden {
            opacity: 0.5;
        }
    }
}

.book-item,
.book-detail .img-box,
.list-type-list .book-item .side-one {
    > .new,
    > .bestseller,
    > .coming-soon {
        box-shadow: -3px 4px 7px 0 rgba(0, 0, 0, 0.3);
        color: $white;
        font-family: 'Inter-Bold';
        font-size: 1rem;
        left: -10px;
        line-height: 150%;
        min-width: 88px;
        padding: 3px;
        position: absolute;
        text-align: center;

        &:first-of-type {
            top: 20px;
        }

        &:nth-of-type(2) {
            top: 55px;
        }

        &:nth-of-type(3) {
            top: 90px;
        }
    }

    .new {
        background-color: #005ef5;
    }

    .bestseller {
        background-color: #1bc200;
    }

    .coming-soon {
        background-color: #fa0;
    }
}

.list-type-list .book-item .side-one {
    position: relative;
}

// if show list design only desktop
.books-tiles.books-list-list,
.books-list.list-type-list {
    @media (min-width: 992px) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-left: 0;
        margin-right: 0;

        .book-item {
            align-items: center;
            border: 1px solid $border;
            border-radius: 8px 0 0 8px;
            display: flex;
            flex-direction: row;
            margin: 0 0 20px;
            width: 100%;

            .img-box {
                background-color: $light;
                border-radius: 8px 0 0 8px;
                border-right: 1px solid $border;
                display: block;
                height: 100%;
                margin: 0;
                padding: 30px;
                width: 196px;
            }

            img,
            .preview.default {
                box-shadow: 0 10px 6px -6px rgba(0, 0, 0, 0.6);
                height: 181px;
                object-fit: contain;
                width: 136px;
            }

            .book-title {
                font-family: 'Inter-Bold';
                font-size: 20px;
                margin-bottom: 8px;
            }

            .book-content {
                margin: 20px 30px;
            }

            .authors {
                font-size: 18px;
            }

            .publication-year {
                margin-bottom: 20px;
            }

            .book-description {
                display: block;
                font-size: 14px;
                height: 84px;
                margin-bottom: 20px;
                overflow: hidden;

                p {
                    display: none;

                    &:first-of-type {
                        display: block;
                    }
                }
            }

            .book-detail-link {
                display: block;
                font-size: 14px;
                text-decoration: underline;
            }
        }
    }
}

.books-tiles:not(.books-list-list),
.books-tiles.books-list-list {
    .book-item.hide-book {
        display: none;
    }
}

.books-list.list-type-list {
    .side-one {
        padding: 30px;
    }

    .side-two {
        padding: 20px 30px;
    }
}

.btn-more {
    display: flex;
    justify-content: center;
    margin: 20px auto;
    width: 100%;

    @media (min-width: 992px) {
        margin: 50px auto;
    }
}
