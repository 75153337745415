@use './../bootstrap/variables' as *;

input,
textarea,
select {
    border: 1px solid $border;
    color: $dark;

    &:disabled {
        background-color: $border;
    }

    &[readonly] {
        background-color: $border;
    }
}

.form-row {
    > label {
        font-family: Inter-Bold, sans-serif;
        margin-bottom: 1rem;
    }

    .checkbox > p {
        font-family: Inter-Bold, sans-serif;
        margin-bottom: 0.5rem;
    }
}

ul[id*='id_radio'] {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    padding-left: 0;

    @media (min-width: 567px) {
        flex-direction: row;
    }

    li {
        list-style-type: none;
        margin-right: 4rem;
        padding: 0.5rem;

        @media (min-width: 567px) {
            padding: 0;
        }

        > label {
            align-items: center;
            display: flex;
        }
    }
}

.one-line-radio div[id^='id_radio'],
.one-line-inputs {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    padding-left: 0;

    @media (min-width: 567px) {
        flex-direction: row;
    }

    div {
        list-style-type: none;
        margin-right: 4rem;
        padding: 0.5rem;

        @media (min-width: 567px) {
            padding: 0;
        }

        > label {
            align-items: center;
            display: flex;
        }
    }
}

.checkbox {
    margin-bottom: 1.5rem;

    label {
        display: block;
        padding: 0.5rem 0 0.5rem 2rem;
    }
}

textarea {
    display: block;
    margin-bottom: 2rem;
    width: 100%;
}

abbr[title] {
    color: $dark;
    margin-left: -2px;
    text-decoration: none;
}

input[type='radio'] {
    appearance: none;
    border-radius: 50%;
    box-shadow: 0 0 0 2px $primary;
    height: 16px;
    margin-right: 1rem;
    outline: none;
    width: 16px;
}

input[type='radio']::before {
    border-radius: 50%;
    content: '';
    display: block;
    height: 100%;
    margin: 0 auto;
    width: 100%;
}

input[type='radio']:checked::before {
    background: $dark;
}

.checkbox {
    input {
        position: absolute;
    }
}

input[type='checkbox'] {
    appearance: none;
    border: 0;
    box-shadow: none;
}

input[type='checkbox']::before,
input[type='checkbox']::after {
    content: '';
    display: inline-block;
    left: -2.1rem;
    position: absolute;
    top: 2.5px;
}

input[name*='boolean'] {
    position: relative;

    &::before,
    &::after {
        left: 0.5rem;
        top: -14.3px;
    }
}

input[type='checkbox']::before {
    border: 2px solid $primary;
    border-radius: 3px;
    height: 18px;
    width: 18px;
}

label input[type='checkbox']:checked::before,
input[name*='boolean']:checked::before {
    background-color: $primary;
    border: 2px solid $primary;
}

input[type='checkbox']::after {
    border-bottom: 2px solid $white;
    border-left: 2px solid $white;
    height: 8px;
    transform: rotate(-45deg) translate(-1px, 4px);
    width: 14px;
}

label input[type='checkbox']::after,
input[name*='boolean']::after {
    content: none;
}

label input[type='checkbox']:checked::after,
input[name*='boolean']:checked::after {
    content: '';
}

.text-danger,
.errormessages {
    color: $dark;
    display: block;
    font-size: 1rem;
    list-style-type: none;
    padding-left: 0;
}

img[alt='captcha'] {
    height: 40px;
    object-fit: contain;
    padding: 0.5rem 0;
    width: 90px;
}
