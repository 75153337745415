@use './../bootstrap/variables' as *;

//radio appearance
ul[id*='id_radioselect'] {
    label {
        left: 2rem;
        position: relative;
        width: calc(100% - 32px);
    }
}

.catalog-books-sort-by,
.checkbox {
    input {
        position: absolute;
    }
}

input[type='radio'],
input[type='checkbox'] {
    appearance: none;
    border: 0;
    box-shadow: none;
}

input[type='radio']::before,
input[type='radio']::after,
input[type='checkbox']::before,
input[type='checkbox']::after {
    content: '';
    display: inline-block;
    left: -1.8rem;
    position: absolute;
    top: 2.5px;
}

input[type='radio']::before,
input[type='checkbox']::before {
    border: 1px solid $border;
    border-radius: 3px;
    height: 20px;
    width: 20px;
}

.collapsing {
    position: relative;
}

input[type='radio']::before {
    border-radius: 50%;
}

label input[type='radio']:checked::before,
label input[type='checkbox']:checked::before {
    background-color: $dark;
    border: 1px solid $dark;
}

input[type='checkbox']::after {
    border-bottom: 2px solid $white;
    border-left: 2px solid $white;
    height: 6px;
    transform: rotate(-45deg) translate(-1.7px, 7.6px);
    width: 13px;
}

label input[type='radio']::after,
label input[type='checkbox']::after {
    content: none;
}

label input[type='radio']:checked::after,
label input[type='checkbox']:checked::after {
    content: '';
}

.has-error {
    input[type='radio']::before,
    input[type='checkbox']::before {
        border-color: $dark;
    }

    input[type='radio'].checkbox-ok::before,
    input[type='checkbox'].checkbox-ok::before {
        border-color: $border;
    }

    label input[type='radio'].checkbox-ok:checked::before,
    label input[type='checkbox'].checkbox-ok:checked::before {
        background-color: $dark;
        border: 1px solid $dark;
    }
}

.label-hidden {
    input,
    textarea {
        background-clip: padding-box;
        box-shadow: -2px -30px 0 -2px $light;

        &:not(:placeholder-shown) {
            // border-color: $border;
            box-shadow: -2px -30px 0 -2px rgba(255, 255, 255, 0);
        }

        ul.errormessages {
            display: block;
        }

        &:not(:placeholder-shown) + ul.errormessages {
            display: none;
        }

        &.email-error {
            &:not(:placeholder-shown) {
                border-color: $dark;
                box-shadow: -2px -30px 0 -2px $info;
            }

            &:not(:placeholder-shown) + ul.errormessages {
                display: block;
            }
        }
    }

    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
        -webkit-text-fill-color: $dark;

        &:not(:placeholder-shown) + ul.errormessages {
            display: none;
        }
    }
}

.label-hidden {
    display: flex;
    flex-direction: column;
    position: relative;

    ul.errormessages {
        order: 1;
        position: absolute;
        z-index: 9;
    }

    label {
        order: 2;
    }

    input,
    textarea {
        order: 3;
    }
}

.has-error {
    border-color: $dark;
    position: relative;

    .text-danger {
        position: absolute;
        top: 3rem;
    }
}

.form {
    label {
        color: $primary;
        font-family: Inter-Bold, sans-serif;
        font-size: 10px;
        margin-bottom: 0.2rem;
        margin-top: 1rem;
    }

    ::placeholder {
        font-family: Inter-Regular,
            sans-serif;
    }

    abbr[title] {
        text-decoration: none;
    }

    label {
        display: block;
    }

    .d-flex aside {
        width: 100%;

        @media (min-width: 768px) {
            width: 50%;
        }
    }

    textarea {
        height: 11rem;
        width: 100%;
    }

    input {
        width: 100%;
    }

    input[type='file'] {
        border: 0;
    }
}

select {
    background-color: $white;
    border: 1px solid $border;
    border-radius: 6px;
    font-size: 18px;
    padding: 2px 5px;
    width: 200px;

    &:active,
    &:focus,
    &:hover {
        border: 1px solid $border;
        box-shadow: none !important;
        outline: none !important;
    }
}
